import Routes from "./routes";
import {MsalProvider} from '@azure/msal-react'

function App({msalInstance}) {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="md:w-[375px] md:h-[600px] w-full h-full bg-black overflow-y-scroll">
        <Routes/>
      </div>
    </MsalProvider>
  );
}

export default App;
