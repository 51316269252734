import React, { Component } from 'react'
import Gallery from '../../components/camera/Gallery';

export class GalleryContainer extends Component {

  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/camera'){
      window.location.replace('/');
    }
  }

  removeGalleryImg = (idx) => {
    this.props.removeGalleryImg(idx)
  }

  render() {
    const {imgGallery} = this.props;
    return <Gallery imgGallery={imgGallery} removeGalleryImg={this.removeGalleryImg} />
  }
}

export default GalleryContainer