import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from '../pages/home/Home';
import ScanPage from '../pages/scan/Scan';
import PhotoListPage from '../pages/photo_list/PhotoList';
import InternalReceivingPage from '../pages/internal_receiving/InternalReceiving';
import ActivitiesPage from '../pages/activities/Activities';
import CameraPage from '../pages/camera/Camera';
import OrderDetailsPage from '../pages/order_details/OrderDetails';
import GalleryPage from '../pages/gallery/Gallery';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage/>} />
      <Route exact path="/scan" element={<ScanPage/>} />
      <Route exact path="/photo-list" element={<PhotoListPage/>} />
      <Route exact path="/internal-receiving" element={<InternalReceivingPage/>} />
      <Route exact path="/activities" element={<ActivitiesPage/>} />
      <Route exact path="/camera" element={<CameraPage/>} />
      <Route exact path="/order-details" element={<OrderDetailsPage/>} />
      <Route exact path="/gallery" element={<GalleryPage/>} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}

export default PrivateRoutes;