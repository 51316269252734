import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar
  } from "../../store/common/commonSlice";
import { removeGalleryImg } from "../../store/camera/cameraSlice";

const mapStateToProps = (state) => {
  const {
    currentPage
  } = state.home;

  const {
    imgGallery
  } = state.camera;

  return {
    currentPage : currentPage,
    imgGallery : imgGallery
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            removeGalleryImg
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;