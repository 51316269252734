import React, { useEffect } from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const Receiving = (props) => {
  const {
    use_cases_details,
    getOrderDetails,
    order_details,
    handleOrderReset,
    handleCurrentPage
  } = props;
  const navigate = useNavigate();
  
  const validationSchema = yup.object().shape({
    activity_id : yup.number().required('please select activity.'),
    po_number: yup.mixed().when('activity_id',{
      is : (value) => value !== 5,
      then :  () => yup.string().required('PO # is required'),
      otherwise : () => yup.string(),
    })
  });

  useEffect(() => {
    if (order_details){
      handleCurrentPage('/camera');
      navigate('/camera');
    }
  },[order_details,handleCurrentPage,navigate])

  const onSubmit = async (values) => {
    const {activity_id} = values;
    if (activity_id !== '5') {
      await getOrderDetails(values);
      formik.resetForm();
    }
    else{
      formik.resetForm();
      handleOrderReset();
      handleCurrentPage('/camera');
      navigate('/camera');
    }
  };

  const formik = useFormik({
    enableReinitialize : true,
    initialValues : {
      activity_id : '',
      po_number : ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <div>
        <p className='text-xl mt-3'>For <strong className='text-white'>Receiving</strong></p>
        <p className='md:mb-6 mb-8 text-xl'>Select the activity to get started.</p>
        <form className='space-y-5' onSubmit={formik.handleSubmit}>
          <div>
            <select 
              className="py-2 px-4 pe-9 block w-full bg-gray-300 border-transparent rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              name="activity"
              value={formik.values.activity_id}
              onChange={formik.handleChange("activity_id")}
              onBlur={formik.handleBlur("activity_id")}
            >
                <option value="" disabled>Select activity</option>
                { 
                    use_cases_details.map((activity,idx) => {
                        return (
                            <option
                            className='text-gray-600'
                              key={idx}
                              value={activity.id}
                            >
                              {activity.name}
                            </option>
                        )
                    })
                }
            </select>
            <p className='text-red-600 text-start text-xs'>{formik.touched.activity_id && formik.errors.activity_id}</p>
          </div>
          {
            (formik.values.activity_id === '' || formik.values.activity_id !== '5') && (
            <div>
              <input 
                type="text" 
                className="bg-gray-300 py-2 px-4 block w-full border-gray-200 rounded-sm text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                placeholder="Enter PO #"
                value={formik.values.po_number}
                onChange={formik.handleChange("po_number")}
                onBlur={formik.handleBlur("po_number")}
              ></input>
              <p 
                className='text-red-600 text-start text-xs'
              >
                {formik.touched.po_number && formik.errors.po_number}
              </p>
            </div>
            )
          }
            <button 
              type="submit" 
              className="text-lg md:px-5 md:py-3 px-6 py-4 font-semibold bg-red-600 text-white rounded-md shadow-sm"
            >
            OK
            </button>
        </form>
    </div>
  )
}

export default Receiving