import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen : false,
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
  orderReceiving : {
    scanType : '',
    selectedActivity : null,
    selectedUseCase : null,
    referenceNo : null,
    order_details : null,
    imgGallery : [],
  }
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSnackBar: (state, action) => {
      state.snackbar = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setSelectedActivity : (state,action) => {
      state.orderReceiving.selectedActivity = action.payload;
      console.log(state.orderReceiving);
    },
    setSelectedUseCase : (state,action) => {
      state.orderReceiving.selectedUseCase = action.payload;
      console.log(state.orderReceiving);
    },
    setScanType : (state,action) => {
      state.orderReceiving.scanType = action.payload;
    },
    setReferenceNo : (state,action) => {
      state.orderReceiving.referenceNo = action.payload;
    },
    setOrderDetails : (state,action) => {
      state.orderReceiving.order_details = action.payload;
    },
    setImgGallery : (state,action) => {
      state.orderReceiving.imgGallery = [...state.orderReceiving.imgGallery,action.payload];
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setSnackBar,
  setIsOpen,
  setSelectedActivity,
  setSelectedUseCase,
  setScanType,
  setOrderDetails
} = common.actions;

export default common.reducer;