import React, { Component } from 'react'
import Scan from '../../components/scan/Scan';
import OrderDetails from '../../components/scan/orderDetails';

export default class ScanContainer extends Component {
  
  componentDidUpdate(){
    const {order_details,navigate} = this.props;
    if (order_details){
      this.props.setPage('/order-details')
      navigate('/order-details');
    }
  }

  callOrderDetails = async (po_number) => {
    let params = {
      po_number : po_number
    }
    await this.props.getOrderDetails(params);
  }
   callUseCaseList = () => {
    this.props.getUseCaseList()
   }

   handleOrderDetails = (order_data) => {
    this.props.setOrderDetails(order_data);
   }

  render() {
    const {order_details,camera,use_case_list} = this.props;
    return (
      <>
        {
          <Scan callOrderDetails={this.callOrderDetails} handleOrderDetails={this.handleOrderDetails} /> 
                  // : <OrderDetails order_details={order_details} callUseCaseList={this.callUseCaseList} use_case_list={use_case_list} />
        }
      </>
    ) 
  }
}