import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
import LayoutView from '../pages/layout/LayoutView.jsx';
import PublicRoutes from './PublicRoutes.jsx';
import { useIsAuthenticated } from '@azure/msal-react';

const Index = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Router>
      <Routes>
        {
          isAuthenticated ? (
            <Route path="/*" element={<LayoutView />} />
          ) : (
            <Route path="/*" element={<PublicRoutes />} />
          )
        }
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  )
}

export default Index;
