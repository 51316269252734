import React from 'react';

const PhotoTable = (props) => {
    const { photo_details } = props;
    return (
        <div className="flex flex-1 flex-col bg-[#3f3f40] text-left text-white w-full overflow-x-scroll rounded-md">
            {photo_details.length > 0 ? (
                <table className='m-3'>
                    <thead>
                        <tr className='text-nowrap'>
                            <th scope="col" className="pl-1 pr-2 py-1 text-start text-xs text-gray-500 dark:text-neutral-500">Ref#</th>
                            <th scope="col" className="pl-1 pr-2 py-1 text-start text-xs text-gray-500 dark:text-neutral-500">Use Case</th>
                            <th scope="col" className="pl-1 pr-2 py-1 text-start text-xs text-gray-500 dark:text-neutral-500">Activity</th>
                            <th scope="col" className="pl-1 pr-2 py-1 text-end text-xs text-gray-500 dark:text-neutral-500">Email</th>
                            <th scope="col" className="pl-1 pr-2 py-1 text-end text-xs text-gray-500 dark:text-neutral-500">BC Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {photo_details.map((photo) =>
                            photo.order_activity_dtl.map((activity, index) => (
                                <tr key={`${photo.id}-${index}`} className='text-nowrap'>
                                    <td className="pl-1 pr-2 py-1 text-xs text-white dark:text-neutral-200">
                                        {index === 0 ? photo.ref_no : ''}
                                    </td>
                                    <td className="pl-1 pr-2 py-1 text-xs text-white dark:text-neutral-200">
                                        {index === 0 ? activity.use_case_dtl.name : ''}
                                    </td>
                                    <td className="pl-1 pr-2 py-1 text-xs text-white ark:text-neutral-20d0">
                                        {activity.activity_dtl.name}
                                    </td>
                                    <td className="pl-1 pr-2 py-1 text-end text-xs">
                                        <button type="button" className="inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400">
                                            Send
                                        </button>
                                    </td>
                                    <td className="pl-1 pr-2 py-1 text-xs text-white ark:text-neutral-20d0">
                                        {photo.bc_update_status ? photo.bc_update_status : 'True'}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            ) : (
                <div className="m-3 text-center text-md text-gray-500">
                    No data found
                </div>
            )}
        </div>
    );
};

export default PhotoTable;
