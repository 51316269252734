import React,{useEffect,useState} from 'react'
import Quagga from 'quagga';
import { parser_get_bc_order_details } from '../../store/home/homeParser';
import axios from 'axios';
import { GET_BC_ORDER_DETAILS } from '../../store/home/homeConstants';
import { baseUrl } from '../../helpers/constants';

let lastDetectedBarcode = null;
let detectionTimestamp = 0;
const Scan = (props) => {
  const {callOrderDetails,handleOrderDetails} = props;
  const [restart,setRestart] = useState(false)

  const stopReader = () => {
    Quagga.stop()
  }

  useEffect(() => {
    setRestart(false);
    Quagga.init({
      inputStream : {
        name : "Live",
        type : "LiveStream",
        target: document.querySelector('#interactive'),
        constraints : {
          width: 640,
          height : 360
        },
      },
      decoder : {
        readers : ["code_39_reader"]
      },
      locate : true,
    }, function(err) {
        if (err) {
          // setCameraStatus(false)
          alert(err.message)
          console.log(err.message);
          return
        }
        console.log("Initialization finished. Ready to start");
        Quagga.start();
        return () => {
          debugger
          Quagga.stop()
        }
        
    });

    Quagga.onProcessed(result => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            Number(drawingCanvas.getAttribute("width")),
            Number(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function(box) {
              return box !== result.box;
            })
            .forEach(function(box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });

    const callOrderDetailsAPI = async (params) => {
      const response = await axios.get(baseUrl + GET_BC_ORDER_DETAILS,{params}).then(async (response)=>{
        const result = response.data;
        const paresedata = await parser_get_bc_order_details(result);
        handleOrderDetails(paresedata);
        setRestart(false);
        return response.data;
      }).catch((err) => {
        alert(err?.response?.data?.detail || err.detail || "Something went wrong!");
        setRestart(true);
        return;
      });
    }

    const detected = async (data) => {
      const newCode = data.codeResult.code;
      const currentTime = Date.now();
      if (currentTime - detectionTimestamp < 50) {
          return;
      }
      detectionTimestamp = currentTime;
      if (lastDetectedBarcode !== newCode) {
        lastDetectedBarcode = newCode;
        stopReader()

        let params = {
          po_number : newCode
        }
        callOrderDetailsAPI(params);
        // await callOrderDetails(data.codeResult.code);
      }
      else {
        // console.log("duplicate barcode data detected")
        lastDetectedBarcode = null
      }
    }
    Quagga.onDetected(detected);
  },[callOrderDetails,restart]);

  return (
    <div className='flex flex-col flex-1 h-full'>
      <div className='flex flex-4 bg-gray-800 h-full w-full overflow-hidden' id="interactive">
        
      </div>
      <div className='flex flex-col justify-center items-center flex-none h-24 bg-black text-white'>
        <p className='mb-0.5'>Scanning...</p>
        <div className='w-6 h-6 bg-gray-700 px-5 py-4 tex-white rounded-md cursor-pointer'>
          <img className="w-full h-full object-contain text-white" src="/images/flash.png" alt="Swagnet Logo" />
        </div>
        <span className='text-xs mt-0.5'>Flash</span>
      </div>
    </div>
  )
}

export default React.memo(Scan);
