import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar
  } from "../../store/common/commonSlice";
import { setGallery,setErr,setCameraOption } from "../../store/camera/cameraSlice";

const mapStateToProps = (state) => {
  const {
    currentPage
  } = state.home;

  const {
    imgGallery,
    err,
    camera_option
  } = state.camera;

  return {
    currentPage : currentPage,
    imgGallery : imgGallery,
    err : err,
    camera_option : camera_option
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            setGallery,
            setErr,
            setCameraOption
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;