export const parser_get_photo_list = (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };