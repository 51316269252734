import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar
  } from "../../store/common/commonSlice";
import { getOrderDetails,setCamera,getUseCaseList,setPage,setOrderDetails } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    order_details,
    camera,
    use_case_list

  } = state.home;

  return {
    order_details : order_details,
    camera : camera,
    use_case_list : use_case_list
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getOrderDetails,
            getUseCaseList,
            setCamera,
            setPage,
            setOrderDetails
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;