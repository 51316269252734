import React from 'react'
import Sidebar from './Sidebar';
import Header from './Header';

const BaseLayout = (props) => {
  const { modules, ChildComponent,handleSidebar,isOpen,username,handleUsername} = props;

  const ToggleSidebar = () => {
      isOpen === true ? handleSidebar(false) : handleSidebar(true);
  }
  return (
    <div className='w-full h-full flex flex-col'>
        <Header 
          ToggleSidebar = {ToggleSidebar} 
          username = {username}
          handleUsername = {handleUsername}
        />
        <Sidebar
          ToggleSidebar = {ToggleSidebar}
          isOpen = {isOpen}
          modules = {modules}
        />
        <main className="md:mt-0 mt-[10.5rem] mx-auto h-full w-full">
          <ChildComponent />
        </main>
    </div>
  )
}

export default BaseLayout