import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setIsOpen } from "../../store/common/commonSlice";
import { setUsername } from "../../store/auth/authSlice";

const mapStateToProps = (state) => {
  const {isOpen} = state.common;
  const {username} = state.auth;
  return {
    isOpen,
    username,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setIsOpen,setUsername }, dispatch);

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
