import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar,
    setSelectedActivity,
    setSelectedUseCase
  } from "../../store/common/commonSlice";
import { getUseCaseList,getOrderDetails,setPage } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    currentPage,
    use_case_list,
    order_details
  } = state.home;

  const {
    orderReceiving,
  } = state.common;

  const {selectedActivity,selectedUseCase} = orderReceiving;

  return {
    currentPage : currentPage,
    use_case_list : use_case_list,
    order_details : order_details,
    selected_use_case : selectedUseCase,
    selected_activity : selectedActivity
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getUseCaseList,
            getOrderDetails,
            setSelectedActivity,
            setSelectedUseCase,
            setPage
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;