import React from 'react';
import { useNavigate } from 'react-router-dom';

const Scan = () => {
  const navigate = useNavigate();
  return (
    <div>
        <p className='text-3xl text-gray-400 mb-3'>Click <strong className='text-white'>Scan</strong> to get started.</p>
        <button 
            className="text-lg 
            md:px-3 md:py-3 
            px-4 py-4 font-semibold 
            bg-red-600 text-white 
            rounded-md shadow-sm mb-7"
            onClick={() => navigate('/scan')}
        >Scan</button>
    </div>
  )
}

export default Scan;