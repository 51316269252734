import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { api_get_bc_order_details_url, api_get_home_url,api_get_use_case_url } from "./homeApi";
import { parser_get_bc_order_details, parser_get_home } from "./homeParser";

export const getHomeList = createAsyncThunk("getHomeList",async (payload) => {
    try {
        const params = {
            usecase_id : payload
        }
        const response = await api_get_home_url(params);
        const data = await parser_get_home(response);
        return {data};
    } catch(error){
        throw new Error(error);
    }
});

export const getUseCaseList = createAsyncThunk("getUseCaseList",async (payload) => {
    try {
        const response = await api_get_use_case_url();
        const data = await parser_get_home(response);
        return {data};
    } catch(error){
        throw new Error(error);
    }
});

export const getOrderDetails = createAsyncThunk("getOrderDetails",async (payload) => {
    try {
        const response = await api_get_bc_order_details_url(payload);
        const data = await parser_get_bc_order_details(response);
        return {data};
    } catch(error){
        throw new Error(error);
    }
});

const initialState = {
    use_cases_details : [],
    use_case_list : [],
    order_details : null,
    is_loading : true,
    camera : true,
    currentPage : ''
}

export const homeSlice = createSlice({
    name : "home",
    initialState,
    reducers : {
        setCamera: (state, action) => {
            state.camera = action.payload;
        },
        resetOrderDetails : (state, action) => {
            state.order_details = null;
        },
        setPage : (state, action) => {
            state.currentPage = action.payload;
        },
        setOrderDetails : (state, action) => {
            state.order_details = action.payload;
        }
    },
    extraReducers : async (builder) => {
        builder
        .addCase(getHomeList.pending, (state, action) => {
            state.is_loading = true;
        })
        .addCase(getHomeList.fulfilled, (state, action) => {
            const { data } = action.payload;
            if (data && data.length > 0){
                const {act_usecases} = data[0];
                state.use_cases_details = act_usecases;
                state.is_loading = false;
            }
            state.is_loading = false;
        })
        .addCase(getHomeList.rejected, (state, action) => {
            state.is_loading = false;
        })

        // get use case list
        .addCase(getUseCaseList.pending, (state, action) => {
            state.is_loading = true;
        })
        .addCase(getUseCaseList.fulfilled, (state, action) => {
            const { data } = action.payload;
            if (data && data.length > 0){
                state.use_case_list = data;
                state.is_loading = false;
            }
            state.is_loading = false;
        })
        .addCase(getUseCaseList.rejected, (state, action) => {
            state.is_loading = false;
        })
          
        //   get order details
        .addCase(getOrderDetails.pending, (state, action) => {
            state.is_loading = true;
        })
        .addCase(getOrderDetails.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.order_details = data;
            state.camera = false;
            state.is_loading = false;
        })
        .addCase(getOrderDetails.rejected, (state, action) => {
            state.camera = true;
            state.is_loading = false;
            state.currentPage = '/scan';
        });
    },
});

export const {
    setCamera,
    setPage,
    resetOrderDetails,
    setOrderDetails
} = homeSlice.actions;

export default homeSlice.reducer;