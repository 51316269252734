import { axios } from "../../helpers/axios";
import { GET_USECASES_URL,GET_BC_ORDER_DETAILS } from "./homeConstants";

export const api_get_home_url = async (params) => {
    return axios.get(GET_USECASES_URL,{params});
};

export const api_get_use_case_url = async () => {
    return axios.get(GET_USECASES_URL);
};

export const api_get_bc_order_details_url = async (params) => {
    return axios.get(GET_BC_ORDER_DETAILS,{params});
};