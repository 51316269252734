import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_get_photo_list_url } from "./photoApi";
import { parser_get_photo_list } from "./photoParser";

export const getPhotoList = createAsyncThunk("getPhotoList", async (payload) => {
    try {
        const { ref_no, page, limit } = payload;
        const params = { ref_no, page, limit };
        const response = await api_get_photo_list_url(params);
        const data = parser_get_photo_list(response);
        return { data };
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    photo_details: [],
    is_loading: true,
    ref_no: '',
    searchValue: '',
    page: 1,
    limit: 5,
    totalItems: 0,
    totalPages: 0,
};

export const photoSlice = createSlice({
    name: "photo_list",
    initialState,
    reducers: {
        setSearchParams(state, action) {
            state.ref_no = action.payload.ref_no;
            state.page = action.payload.page || state.page;
            state.limit = action.payload.limit || state.limit;
        },
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        goToNextPage(state) {
            if (state.page < state.totalPages) {
                state.page += 1;
            }
        },
        goToPrevPage(state) {
            if (state.page > 1) {
                state.page -= 1;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPhotoList.pending, (state) => {
                state.is_loading = true;
            })
            .addCase(getPhotoList.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.photo_details = data.photos;
                state.totalItems = data.total;
                state.totalPages = Math.ceil(data.total / state.limit);
                state.is_loading = false;
            })
            .addCase(getPhotoList.rejected, (state) => {
                state.is_loading = false;
            });
    },
});

export const { setSearchParams, setSearchValue, goToNextPage, goToPrevPage } = photoSlice.actions;
export default photoSlice.reducer;
