import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSnackBar
  } from "../../store/common/commonSlice";
import { getHomeList, getOrderDetails,resetOrderDetails,setPage } from "../../store/home/homeSlice";

const mapStateToProps = (state) => {
  const {
    use_cases_details,
    order_details,
    is_loading
  } = state.home;

  return {
    use_cases_details : use_cases_details,
    order_details : order_details,
    is_loading : is_loading
  };
};

const mapDispatchToProps = (dispatch) => 
    bindActionCreators(
        {
            setSnackBar,
            getHomeList,
            getOrderDetails,
            resetOrderDetails,
            setPage
        },
        dispatch
    );


const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;