import { get } from "lodash-es";

export const parser_get_home = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

export const parser_get_bc_order_details = async (response) => {
    try {
      if (response?.data) {
        response = response.data;
      }
      if (!response) {
        return {};
      }
      return {
        SalesOrderNo : get(response,"sale_order_no",""),
        CustomerName : get(response,"customer_name",""),
        CustomerEmail : get(response,"customer_email",""),
        CustomerNo : get(response,"customer_no",""),
        ShippingDate : get(response,"shippment_date",""),
        ShippingFrom : get(response,"shipping_from",""),
        ShippingAgent : get(response,"shipping_agent",""),
        ShippingMethod : get(response,"shipping_method",""),
        Csr : get(response,"csr",""),
      };
    } catch (error) {
      throw new Error(error);
    }
  };