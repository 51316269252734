import React, { useEffect } from 'react';
import Webcam from "react-webcam";

const WebCamera = (props) => {
  const {webcamRef,handleMediaError,is_loading,camera_option} = props;
  const videoConstraints = {
      facingMode: camera_option === "user" ? "user" : { exact: "environment" },
  };

  useEffect(() => {
  },[camera_option])  
  return (
    <Webcam
        audio={false}
        mirrored={true}
        style={{
            width : '100%',
            height : '100%',
            opacity : is_loading ? 0.5 : 1,
            transition: "opacity 0.1s ease",
            objectFit : "fill"
        }}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMediaError = {handleMediaError}
    />
  )
}

export default WebCamera;