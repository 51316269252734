import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  imgGallery : [],
  err : "",
  camera_option : "user"
}

export const cameraSlice = createSlice({
  name : "camera",
  initialState,
  reducers : {
      setGallery: (state, action) => {
        state.imgGallery = [action.payload,...state.imgGallery];
      },
      setErr : (state,action) => {
        state.err = action.payload;
      },
      removeGalleryImg : (state,action) => {
        state.imgGallery = state.imgGallery.filter((img,idx)=> idx !== action.payload);
      },
      setCameraOption : (state,action) => {
        state.camera_option = action.payload;
      }

  }
});

export const {
  setGallery,
  setErr,
  removeGalleryImg,
  setCameraOption
} = cameraSlice.actions;

export default cameraSlice.reducer;