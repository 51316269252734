import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { store } from "./store/index.js";
import {PublicClientApplication,EventType} from '@azure/msal-browser'

const pca = new PublicClientApplication({
  auth : {
    clientId : 'cd773b87-a58a-4b07-a76c-217b353963de',
    authority : 'https://login.microsoftonline.com/c011b2d2-894f-4ee2-b3c9-bb98ef38e0cd',
    redirectUri : '/'
  },
  cache : {
    cacheLocation : 'localStorage',
    storeAuthStateInCookie: false,
  },
  system : {
    // loggerOptions : {
    //   loggerCallback : (level, message, containsPII) => {
    //     console.log(message);
    //   },
    //   logLevel : 'Info'
    // }

  }
});

pca.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App msalInstance={pca} />
    </Provider>
  </React.StrictMode>
);