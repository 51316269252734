import React, { Component } from 'react';
import Search from '../../components/photo_list/Search';
import PhotoTable from '../../components/photo_list/PhotoTable';

export default class PhotoListContainer extends Component {
    componentDidMount() {
        const { ref_no, page, limit } = this.props;
        this.props.getPhotoList({ ref_no, page, limit });
    }

    handleSearch = () => {
        const searchRefNo = this.props.searchValue.trim();
        this.props.setSearchParams({ ref_no: searchRefNo, page: 1 });
        this.props.getPhotoList({ ref_no: searchRefNo, page: 1, limit: this.props.limit });
    };

    handlePrevPage = () => {
        this.props.goToPrevPage();
        this.props.getPhotoList({
            ref_no: this.props.ref_no,
            page: this.props.page - 1,
            limit: this.props.limit
        });
    };

    handleNextPage = () => {
        this.props.goToNextPage();
        this.props.getPhotoList({
            ref_no: this.props.ref_no,
            page: this.props.page + 1,
            limit: this.props.limit
        });
    };

    render() {
        const { photo_details, is_loading, page, totalPages, totalItems, searchValue, setSearchValue } = this.props;

        return (
            <section className='flex flex-col flex-1 h-full mx-2.5 pb-2.5'>
                <Search
                    searchValue={searchValue}
                    onSearch={this.handleSearch}
                    onPrevPage={this.handlePrevPage}
                    onNextPage={this.handleNextPage}
                    page={page}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    setSearchValue={setSearchValue}
                />
                <PhotoTable photo_details={photo_details} is_loading={is_loading} />
            </section>
        );
    }
}
