import React, { Component } from 'react'
import Camera from '../../components/camera/Camera';

export class CameraContainer extends Component {

  handleGalleryImg = (imgData) => {
    this.props.setGallery(imgData);
  }

  handleErr = (message) => {
    this.props.setErr(message);
  }
  
  componentDidMount(){
    const {currentPage} = this.props;
    if (currentPage !== '/camera'){
      window.location.replace('/');
    }
  }

  handleCameraOption = (newVal) => {
    this.props.setCameraOption(newVal);
  }

  render() {
    const {imgGallery,err,camera_option} = this.props;
    return <Camera 
            handleGalleryImg={this.handleGalleryImg} 
            imgGallery={imgGallery}
            handleErr = {this.handleErr}
            err = {err}
            handleCameraOption = {this.handleCameraOption}
            camera_option = {camera_option}
          />
  }
}

export default CameraContainer;