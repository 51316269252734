import React, { Component } from 'react'
import Scan from '../../components/home/Scan';
import Receiving from '../../components/home/Receiving';

export default class HomeContainer extends Component {
    componentDidMount(){
        this.props.getHomeList(1);
    }

    handleOrderReset = () => {
        this.props.resetOrderDetails();
    }

    handleSubmit = async (values) => {
        let requestBody = values;
        delete requestBody.activity_id;
        await this.props.getOrderDetails(requestBody);
    };

    handleCurrentPage = (newPage) => {
        this.props.setPage(newPage)
    }
    
    render() {
        const {use_cases_details,order_details} = this.props;
        return (
            <section className='flex flex-col px-5 pb-5'>
                <div className='text-center text-gray-400 flex-1'>
                    <Scan/>
                    <hr/>
                    {/* Receiving Section */}
                    <Receiving
                        use_cases_details = {use_cases_details}
                        getOrderDetails = {this.handleSubmit}
                        order_details = {order_details}
                        handleOrderReset = {this.handleOrderReset}
                        handleCurrentPage = {this.handleCurrentPage}
                    />
                </div>
                <footer className='text-gray-400 text-center md:mt-2 mt-4'>Powered by Addnectar Solutions</footer>
            </section>
        )
    }
}
